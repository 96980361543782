import {
  Text,
  VStack,
  Stack,
  Grid,
  Button,
  AspectRatio,
  Link,
} from '@chakra-ui/react'
import Image from 'next/image'
import { useTranslation, Trans } from 'next-i18next'

import { RISK_ASSESSMENT_TYPEFORM } from '@/routes/external'

import HeroImage from '@/images/resources/riskAssessment/hero.svg'

interface Props {
  onClickDownload?: () => void
}

export function RiskAssessmentBanner({
  onClickDownload,
}: Props): React.ReactElement {
  const { t } = useTranslation('riskAssessment')

  return (
    <Grid
      layerStyle="container"
      sx={{
        gridTemplateColumns: {
          base: '1fr',
          md: 'repeat(2, 1fr)',
        },
        gridGap: {
          base: 6,
          md: '60px',
        },
        alignItems: 'center',
      }}
    >
      <AspectRatio ratio={612 / 538}>
        <Image
          priority
          src={HeroImage}
          alt="hero"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </AspectRatio>
      <VStack
        spacing="4"
        sx={{
          fontWeight: 'medium',
          alignItems: {
            base: 'center',
            md: 'flex-start',
          },
          color: 'white',
          textAlign: {
            base: 'center',
            md: 'left',
          },
          gridColumn: {
            base: '1',
            md: '2',
          },
        }}
      >
        <Text
          as="h1"
          sx={{
            color: 'gray.900',
            fontWeight: 'medium',
            fontSize: {
              base: '3xl',
              sm: '5xl',
              md: '5.5xl',
            },
            lineHeight: {
              base: '50px',
              sm: '68px',
              md: '72px',
            },
          }}
        >
          <Trans t={t} i18nKey="hero.title">
            <Text
              as="span"
              sx={{ color: 'primary.400', lineHeight: 'inherit' }}
            >
              แจกฟรี!
            </Text>
            แนวปฏิบัติ PDPA สำหรับองค์กร
          </Trans>
        </Text>
        <Text
          as="h2"
          sx={{
            color: 'gray.900',
            fontSize: {
              base: 'xl',
              sm: '3.5xl',
            },
            fontWeight: 'normal',
          }}
        >
          <Trans t={t} i18nKey="hero.subtitle">
            เอกสารรายละเอียดบริการ
            <Text as="strong" sx={{ fontWeight: 'medium' }}>
              พร้อมแบบประเมินความเสี่ยงด้วยตัวเองฉบับย่อ
            </Text>{' '}
            ดาวน์โหลดฟรี !
          </Trans>
        </Text>
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            md: 'row',
          }}
          sx={{
            pt: {
              base: 8,
              sm: 12,
            },
          }}
        >
          <Button
            as={Link}
            href={RISK_ASSESSMENT_TYPEFORM.pathname}
            isExternal={RISK_ASSESSMENT_TYPEFORM.isExternal}
            size="xl"
            onClick={onClickDownload}
            sx={{
              alignSelf: 'center',
              w: { base: 'full', md: 'fit-content' },
              mx: 'auto',
              cursor: 'pointer',
              color: 'white !important',
            }}
          >
            {t('hero.cta')}
          </Button>
        </Stack>
      </VStack>
    </Grid>
  )
}
