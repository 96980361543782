import {
  Text,
  VStack,
  Stack,
  Grid,
  Button,
  AspectRatio,
} from '@chakra-ui/react'
import Image from 'next/image'
import { useTranslation, Trans } from 'next-i18next'

import { DATASHEET_FORM } from '@/routes/resources'

import ScrollLink from '@/lib/scroll'

import HeroImage from '@/images/resources/datasheet/download-hero.png'

interface Props {
  onClickDownload?: () => void
}

export function DownloadDatasheetBanner({
  onClickDownload,
}: Props): React.ReactElement {
  const { t } = useTranslation('datasheet')

  return (
    <Grid
      sx={{
        gridTemplateColumns: {
          base: '1fr',
          md: '2fr 1fr',
        },
        gridGap: {
          base: 12,
          md: 0,
        },
        alignItems: 'center',
      }}
    >
      <VStack
        spacing="4"
        sx={{
          fontWeight: 'medium',
          alignItems: {
            base: 'center',
            md: 'flex-start',
          },
          color: 'white',
          textAlign: {
            base: 'center',
            md: 'left',
          },
          gridColumn: '1',
          gridRow: {
            base: '2',
            md: '1',
          },
        }}
      >
        <Text
          as="h1"
          sx={{
            color: 'gray.900',
            fontWeight: 'medium',
            fontSize: '56px',
            lineHeight: {
              base: '50px',
              sm: '68px',
              md: '72px',
            },
          }}
        >
          <Trans t={t} i18nKey="download.banner.title">
            <Text
              as="span"
              sx={{
                color: 'primary.400',
                lineHeight: 'inherit',
              }}
            >
              PDPA Implementation Datasheet
            </Text>
          </Trans>
        </Text>
        <Text
          as="h2"
          sx={{
            color: 'gray.900',
            fontSize: '28px',
            fontWeight: 'normal',
          }}
        >
          <Trans t={t} i18nKey="download.banner.subtitle">
            รายละเอียดบริการ
            <Text
              as="strong"
              sx={{
                fontWeight: 'medium',
              }}
            >
              PDPA Implementation
            </Text>
          </Trans>
        </Text>
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            md: 'row',
          }}
          sx={{
            pt: 4,
          }}
        >
          <Button
            as={ScrollLink}
            url={DATASHEET_FORM}
            size="xl"
            onClick={onClickDownload}
            sx={{
              alignSelf: 'center',
              w: { base: 'full', md: 'fit-content' },
              mx: 'auto',
              px: 12,
              cursor: 'pointer',
              color: 'white !important',
            }}
          >
            {t('download.banner.cta')}
          </Button>
        </Stack>
      </VStack>
      <AspectRatio
        ratio={329 / 408}
        maxW={329}
        sx={{
          w: '100%',
          justifySelf: {
            base: 'center',
            md: 'flex-end',
          },
        }}
      >
        <Image
          priority
          src={HeroImage}
          alt="hero"
          layout="fill"
          objectFit="cover"
        />
      </AspectRatio>
    </Grid>
  )
}
